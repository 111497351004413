import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(
    private alertService: ToastrService
  ) { }

  success(message: string, title?: string) {
    this.alertService.success(message, title);
  }

  error(message: string, title?: string) {
    this.alertService.error(message, title);
  }

  warning(message: string, title?: string) {
    this.alertService.warning(message, title);
  }

  info(message: string, title?: string) {
    this.alertService.info(message, title);
  }

}
